<!--
 * @Description:用户端-首页-共享停车-共享发布 shareIssue
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-01 13:06:24
 * @LastEditors: yanxiao
-->
<template>
  <div class='body'>
    <van-nav-bar title="共享发布"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-row>
        <van-cell-group>
          <van-field clickable
                     v-model="area"
                     label="区域选择"
                     placeholder="请选择"
                     @click="showPickerArea = true" />
          <van-field clickable
                     v-model="address"
                     label="详细地址"
                     placeholder="请选择"
                     @click="showPickerAddress = true" />
          <van-row class="remark">
            <span style="color:red;">*</span>若列表中未查询到，需向您所在小区/商场停车位运 营人员确认是否可进行
          </van-row>
          <van-field v-model="parkingDetails"
                     placeholder="详细车位信息"
                     input-align="left"
                     class="inputDetails" />
          <van-row>
            <van-col>
              <van-field v-model="sharePrices"
                         label="共享价格"
                         placeholder="请输入"
                         style="width:275px">
              </van-field>
            </van-col>
            <van-col>
              <span style="display:block;margin-top:15px;color:#666666;">元/月</span>
            </van-col>
          </van-row>
          <van-field readonly
                     clickable
                     v-model="startTime"
                     label="共享时段"
                     placeholder="开始时间"
                     @click="showPickerStartTime = true" />
          <van-field readonly
                     clickable
                     v-model="endTime"
                     label="    "
                     placeholder="结束时间"
                     @click="showPickerEndTime = true" />
          <van-field v-model="contactNumber"
                     label="联系电话"
                     placeholder="请输入联系电话" />
        </van-cell-group>
      </van-row>
    </van-row>
    <!-- 区域选择 -->
    <van-popup v-model="showPickerArea"
               position="bottom"
               round
               :style="{ height: '40%' }">
      <van-picker title="区域选择"
                  show-toolbar
                  :columns="columnsArea"
                  @confirm="onConfirmArea"
                  @cancel="onCancelArea"
                  @change="onChangeArea" />
    </van-popup>
    <!-- 详细地址选择 -->
    <van-popup v-model="showPickerAddress"
               position="bottom"
               round
               :style="{ height: '40%' }">
      <van-picker title="区域选择"
                  show-toolbar
                  :columns="columnsAddress"
                  @confirm="onConfirmAddress"
                  @cancel="onCancelAddress"
                  @change="onChangeAddress" />
    </van-popup>
    <!-- 开始时间选择 -->
    <van-popup v-model="showPickerStartTime"
               position="bottom"
               round
               :style="{ height: '40%' }">
      <van-datetime-picker v-model="startCurrentDate"
                           type="datetime"
                           title="开始时间"
                           :min-date="startMinDate"
                           :max-date="startMaxDate"
                           @confirm="onConfirmStartTime"
                           @cancel="onCancelStartTime"
                           @change="onChangeStartTime" />
    </van-popup>
    <!-- 结束时间选择 -->
    <van-popup v-model="showPickerEndTime"
               position="bottom"
               round
               :style="{ height: '40%' }">
      <van-datetime-picker v-model="endCurrentDate"
                           type="datetime"
                           title="结束时间"
                           :min-date="endMinDate"
                           :max-date="endMaxDate"
                           @confirm="onConfirmStartEnd"
                           @cancel="onCancelStartEnd"
                           @change="onChangeStartEnd" />
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
// import { ref } from 'vue'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      // 区域选择器
      showPickerArea: false,
      // 详细地址选择器
      showPickerAddress: false,
      // 开始时间按选择器
      showPickerStartTime: false,
      // 结束时间选择器
      showPickerEndTime: false,
      area: '',
      address: '',
      parkingDetails: '',
      sharePrices: '',
      startTime: '',
      endTime: '',
      contactNumber: '',
      columnsArea: ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华', '衢州'],
      columnsAddress: ['福州', '厦门', '莆田', '三明', '泉州'],
      startMinDate: new Date(2020, 0, 1),
      startMaxDate: new Date(2025, 10, 1),
      startCurrentDate: new Date(),
      endMinDate: new Date(2020, 0, 1),
      endMaxDate: new Date(2025, 10, 1),
      endCurrentDate: new Date()
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    handleClickTopBack () {
      this.$router.go(-1)
    },
    // setup () {
    //   const currentTime = ref('12:00')
    //   return { currentTime }
    //   // },
    // 区域选择
    onConfirmArea (value) {
      this.area = value
      this.showPickerArea = false
    },
    onCancelArea () {
      this.showPickerArea = false
    },
    onChangeArea () { },
    // 详细地址选择
    onConfirmAddress (value) {
      this.address = value
      this.showPickerAddress = false
    },
    onCancelAddress () {
      this.showPickerAddress = false
    },
    onChangeAddress () { },
    // 开始时间选择
    onConfirmStartTime (value) {
      var time = this.$fmtDate(value, 'y年M月d日 h:m')
      this.startTime = time
      this.showPickerStartTime = false
    },
    onCancelStartTime () {
      this.showPickerStartTime = false
    },
    onChangeStartTime () { },
    // 结束时间选择
    onConfirmStartEnd (value) {
      var time = this.$fmtDate(value, 'y年M月d日 h:m')
      this.endTime = time
      this.showPickerEndTime = false
    },
    onCancelStartEnd () {
      this.showPickerEndTime = false
    },
    onChangeStartEnd () { }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    width: 93.6%;
    // height: 67.2%;
    margin: 0 3.2%;
    // padding: 0 13.5px;
    background: #fff;
    margin-top: 1.9%;
    border-radius: 5px;
    .remark {
      width: 232px;
      color: #999;
      font-size: 10px;
      margin-left: 115px;
    }
    .inputDetails {
      height: 170px;
    }
  }
}
.popupTitle {
  text-align: center;
}
</style>
